/*
 * Created by Andre Richards on 3 March 2021.
 */

import React, {useCallback} from 'react';
import PropTypes from 'prop-types';
import {
    Select, SideDrawer, SideDrawerContent, SideDrawerToolbar, TextField,
} from "@hti-ui/react-web-material";
import {Button, FormControlLabel, Grid, makeStyles, Switch} from "@material-ui/core";
import useGlobalMessenger from "../../hooks/useGlobalMessenger";
import useActions from "../../hooks/useActions";
import {
    createPaymentGatewayConfig, updatePaymentGatewayConfig
} from "../../actions/paymentGatewayConfigActions";
import configFields from "./Fields";
import gatewayFields from "../Util/GatewayFields"

/**
 * A drawer for creating and updating a PaymentGatewayConfig entity.
 */
const Drawer = props => {

    const classes = useStyles();const {
        visible, disabled, dismiss, paymentGatewayConfig, customers, paymentGateways
    } = props;
    const isNewPaymentGatewayConfig = !paymentGatewayConfig;
    const paymentGatewayConfigId = paymentGatewayConfig?.id;

    const {dispatchSuccessMessage, handleError} = useGlobalMessenger();
    const [_createPaymentGatewayConfig, _updatePaymentGatewayConfig,] = useActions([createPaymentGatewayConfig, updatePaymentGatewayConfig,]);

    const [uniqueKey, setUniqueKey] = React.useState('');
    const [customerId, setCustomerId] = React.useState(undefined);
    const [paymentGatewayId, setPaymentGatewayId] = React.useState(undefined);
    const [merchantId, setMerchantId] = React.useState('');
    const [siteId, setSiteId] = React.useState('');
    const [secretKey, setSecretKey] = React.useState('');
    const [secretToken, setSecretToken] = React.useState('');
    const [username, setUsername] = React.useState('');
    const [password, setPassword] = React.useState('');
    const [note, setNote] = React.useState('');
    const [testMode, setTestMode] = React.useState(false);
    const [skipSuccessPage, setSkipSuccessPage] = React.useState(true);
    const [skipErrorPage, setSkipErrorPage] = React.useState(false);
    const [displayName, setDisplayName] = React.useState('');
    const [acquirer, setAcquirer] = React.useState('');
    const [tokenize, setTokenize] = React.useState(false);

    React.useEffect(() => {
        setUniqueKey(paymentGatewayConfig?.uniqueKey ?? '');
        setCustomerId(paymentGatewayConfig?.customerId ?? '');
        setPaymentGatewayId(paymentGatewayConfig?.paymentGatewayId ?? '');
        setMerchantId(paymentGatewayConfig?.merchantId ?? '');
        setSiteId(paymentGatewayConfig?.siteId ?? '');
        setSecretKey(paymentGatewayConfig?.secretKey ?? '');
        setSecretToken(paymentGatewayConfig?.secretToken ?? '');
        setUsername(paymentGatewayConfig?.username ?? '');
        setPassword(paymentGatewayConfig?.password ?? '');
        setNote(paymentGatewayConfig?.note ?? '');
        setDisplayName(paymentGatewayConfig?.displayName ?? '');
        setAcquirer(paymentGatewayConfig?.acquirer ?? '');
        setTokenize(paymentGatewayConfig?.tokenize ?? false);
    }, [visible, paymentGatewayConfig]);

    const _customers = React.useMemo(() => customers.map(({id, name}) => {
        return {
            label: name, value: id,
        }
    }), [customers]);

    const _paymentGateways = React.useMemo(() => paymentGateways
        .filter(({disabled}) => !disabled)
        .map(({id, name, disabled}) => {
            return {
                label: name, value: id,
        }
    }), [paymentGateways, disabled]);

    const acquirerList = React.useMemo(() => {
        return [{
            label: "Thumbzup - ABSA", value: "ABSA"
        }, {
            label: "Ecentric - NEDBANK", value: "NEDBANK"
        }]
    }, []);

    const canCommit = !!customerId && !!paymentGatewayId;

    /**
     * Creates a new PaymentGatewayConfig. If successful, the drawer is dismissed.
     * The user is notified of the success or failure of the request.
     */
    const onCreateClicked = React.useCallback(() => {

        _createPaymentGatewayConfig({
            // uniqueKey,
            customerId,
            paymentGatewayId,
            merchantId,
            siteId,
            secretKey,
            secretToken,
            username,
            password,
            note,
            displayName,
            testMode,
            acquirer,
            tokenize
        })
            .then(() => {
                dispatchSuccessMessage('The Payment Gateway Config has been created.');
                dismiss();
            })
            .catch(e => void handleError(e, 'The Payment Gateway Config could not be created.'));

    }, [// uniqueKey,
        customerId, paymentGatewayId, merchantId, siteId, secretKey, secretToken, username, password, note, displayName,
        _createPaymentGatewayConfig, dispatchSuccessMessage, handleError, dismiss, testMode, acquirer, tokenize]);

    const getFieldDetails = React.useCallback((gatewayName, configField) => {
        const fields = gatewayFields;
        const gatewayFieldsMap = fields[gatewayName];
        const configFields = gatewayFieldsMap['payment_gateway_config_fields'];
        return configFields.get(configField);
    }, [])

    const isRequired = React.useCallback((configField) => {
        const gatewaySelected = paymentGateways.find(function (gateway) {
            return gateway.id === paymentGatewayId;
        });
        if (gatewaySelected != null) {
            const gatewayName = gatewaySelected?.name;
            const fieldDetails = getFieldDetails(gatewayName, configField);
            if (fieldDetails != null) {
                if (fieldDetails['required']) {
                    return true;
                } else {
                    return false;
                }
                return true;
            } else {
                return false;
            }
        } else {
            // no gateway found
            return true;
        }

    }, [paymentGatewayId, paymentGateways]);

    const addGatewayFieldNameToLabel = React.useCallback((configField) => {
        const gatewaySelected = paymentGateways.find(function (gateway) {
            return gateway.id === paymentGatewayId;
        });
        if (gatewaySelected != null) {
            const gatewayName = gatewaySelected?.name;
            const fieldDetails = getFieldDetails(gatewayName, configField);
            if (fieldDetails != null) {
                if (fieldDetails['gatewayName']) {
                    return configField + " / " + fieldDetails['gatewayName'];
                } else {
                    return configField;
                }
                return configField;
            } else {
                return configField;
            }
        } else {
            // no gateway found
            return configField;
        }

    }, [paymentGatewayId, paymentGateways]);

    const showField = React.useCallback((configField) => {
        const gatewaySelected = paymentGateways.find(function (gateway) {
            return gateway.id === paymentGatewayId;
        });
        if (gatewaySelected != null) {
            const gatewayName = gatewaySelected?.name;
            const fieldDetails = getFieldDetails(gatewayName, configField);
            if (fieldDetails != null) {
                return true;
            } else {
                return false;
            }
        } else {
            // no gateway found
            return true;
        }
    }, [paymentGatewayId, paymentGateways]);

    /**
     * Updates an existing PaymentGatewayConfig. If successful, the drawer is dismissed.
     * The user is notified of the success or failure of the request.
     */
    const onUpdateClicked = React.useCallback(() => {

        _updatePaymentGatewayConfig({
            id: paymentGatewayConfigId,
            uniqueKey,
            customerId,
            paymentGatewayId,
            merchantId,
            siteId,
            secretKey,
            secretToken,
            username,
            password,
            note,
            tokenize,
      displayName,
            testMode,
            acquirer
        })
            .then(() => {
                dispatchSuccessMessage('The Payment Gateway Config has been updated.');
                dismiss();
            })
            .catch(e => void handleError(e, 'The Payment Gateway Config could not be updated.'));

  }, [
    paymentGatewayConfigId,
    uniqueKey,
    customerId,
    paymentGatewayId,
    merchantId,
    siteId,
    secretKey,
    secretToken,
    username,
    password,
    note,
    displayName,
    _updatePaymentGatewayConfig, dispatchSuccessMessage, handleError,
    dismiss,
        acquirer,
        tokenize
  ]);

    return (<SideDrawer visible={visible} md={4} sm={6} xs={12}>

        <SideDrawerToolbar
            title={isNewPaymentGatewayConfig ? 'New Config' : 'Updating Config'}
        >

            <Button
                variant={'outlined'}
                onClick={dismiss}
                disabled={disabled}
            >Cancel</Button>

            <Button
                variant={'contained'}
                color={'secondary'}
                onClick={isNewPaymentGatewayConfig ? onCreateClicked : onUpdateClicked}
                disabled={disabled || !canCommit}
            >{isNewPaymentGatewayConfig ? 'Create' : 'Update'}</Button>

        </SideDrawerToolbar>

        <SideDrawerContent>

            <Grid container spacing={1}>

                <Grid item xs={12}>
                    <TextField
                        label={configFields.UNIQUE_KEY}
                        value={uniqueKey}
                        // onChange={setUniqueKey}
                        fullWidth
                        disabled
                        // required
                    />
                </Grid>

                <Grid item xs={12} hidden={!showField(configFields.CUSTOMER)}>
                    <Select
                        label={configFields.CUSTOMER}
                        value={customerId}
                        options={_customers}
                        onChange={setCustomerId}
                        fullWidth
                        required
                    />
                </Grid>

                <Grid item xs={12} hidden={!showField(configFields.CUSTOMER_ID)}>
                    <TextField
                        label={configFields.CUSTOMER_ID}
                        value={customerId || 0}
                        onChange={() => {
                        }}
                        fullWidth
                        disabled={true}
                    />
                </Grid>

                <Grid item xs={12} hidden={!showField(configFields.PAYMENT_GATEWAY)}>
                    <Select
                        label={configFields.PAYMENT_GATEWAY}
                        value={paymentGatewayId}
                        options={_paymentGateways}
                        onChange={setPaymentGatewayId}
                        fullWidth
                        required
                    />
                </Grid>

                <Grid item xs={12} hidden={!showField(configFields.MERCHANT_ID)}>
                    <TextField
                        label={addGatewayFieldNameToLabel(configFields.MERCHANT_ID)}
                        value={merchantId}
                        onChange={setMerchantId}
                        fullWidth
                        required={isRequired(configFields.MERCHANT_ID)}
                    />
                </Grid>

                <Grid item xs={12} hidden={!showField(configFields.SITE_ID)}>
                    <TextField
                        label={addGatewayFieldNameToLabel(configFields.SITE_ID)}
                        value={siteId}
                        onChange={setSiteId}
                        fullWidth
                        required={isRequired(configFields.SITE_ID)}
                    />
                </Grid>

                <Grid item xs={12} hidden={!showField(configFields.SECRET_KEY)}>
                    <TextField
                        label={addGatewayFieldNameToLabel(configFields.SECRET_KEY)}
                        type='password'
                        value={secretKey}
                        onChange={setSecretKey}
                        fullWidth
                        required={isRequired(configFields.SECRET_KEY)}
                    />
                </Grid>

                <Grid item xs={12} hidden={!showField(configFields.SECRET_TOKEN)}>
                    <TextField
                        label={addGatewayFieldNameToLabel(configFields.SECRET_TOKEN)}
                        type='password'
                        value={secretToken}
                        onChange={setSecretToken}
                        fullWidth
                        required={isRequired(configFields.SECRET_KEY)}
                    />
                </Grid>

                <Grid item xs={12} hidden={!showField(configFields.USERNAME)}>
                    <TextField
                        label={addGatewayFieldNameToLabel(configFields.USERNAME)}
                        value={username}
                        onChange={setUsername}
                        fullWidth
                        required={isRequired(configFields.USERNAME)}
                    />
                </Grid>

                <Grid item xs={12} hidden={!showField(configFields.PASSWORD)}>
                    <TextField
                        label={addGatewayFieldNameToLabel(configFields.PASSWORD)}
                        type='password'
                        value={password}
                        onChange={setPassword}
                        fullWidth
                        required={isRequired(configFields.PASSWORD)}
                    />
                </Grid>

        <Grid item xs={12} hidden={!showField(configFields.TOKENIZE)}>
          <FormControlLabel
              className={classes.switch}
              label={'Tokenize'}
              control={<Switch
                  checked={tokenize}
                  onChange={({target: {checked}}) => setTokenize(
                      checked)}
              />}
              disabled={false}
          />
        </Grid>

                <Grid item xs={12} hidden={!showField(configFields.ACQUIRER)}>
                    <Select
                        label={configFields.ACQUIRER}
                        value={acquirer}
                        options={acquirerList}
                        onChange={setAcquirer}
                        fullWidth
                        required
                    />
                </Grid>

                <Grid item xs={12} hidden={!showField(configFields.DISPLAY_NAME)}>
                    <TextField
                        label={addGatewayFieldNameToLabel(configFields.DISPLAY_NAME)}
                        value={displayName}
                        onChange={setDisplayName}
                        fullWidth
                        required={isRequired(configFields.DISPLAY_NAME)}
                    />
                </Grid>

                <Grid item xs={12} hidden={!showField(configFields.NOTE)}>
                    <TextField
                        label={addGatewayFieldNameToLabel(configFields.NOTE)}
                        value={note}
                        onChange={setNote}
                        fullWidth
                        required={isRequired(configFields.NOTE)}
                    />
                </Grid>

                <Grid item xs={12} hidden={!showField(configFields.TEST_MODE)}>
                    <FormControlLabel
                        label={addGatewayFieldNameToLabel(configFields.TEST_MODE)}
                        control={<Switch
                            checked={testMode}
                            onChange={({target: {checked}}) => setTestMode(checked)}
                        />}
                    />
                </Grid>
                <Grid item xs={12} hidden={!showField(configFields.SKIP_SUCCESS_PAGE)}>
                    <FormControlLabel
                        label={addGatewayFieldNameToLabel(configFields.SKIP_SUCCESS_PAGE)}
                        control={<Switch
                            checked={skipSuccessPage}
                            onChange={({target: {checked}}) => setSkipSuccessPage(checked)}
                        />}
                    />
                </Grid>
                <Grid item xs={12} hidden={!showField(configFields.SKIP_ERROR_PAGE)}>
                    <FormControlLabel
                        label={addGatewayFieldNameToLabel(configFields.SKIP_ERROR_PAGE)}
                        control={<Switch
                            checked={skipErrorPage}
                            onChange={({target: {checked}}) => setSkipErrorPage(checked)}
                        />}
                    />
                </Grid>

            </Grid>

        </SideDrawerContent>

    </SideDrawer>);

};

Drawer.propTypes = {
    visible: PropTypes.bool.isRequired,
    disabled: PropTypes.bool.isRequired,
    dismiss: PropTypes.func.isRequired,
    paymentGatewayConfig: PropTypes.object,
};

const useStyles = makeStyles(theme => ({
  switch: {
    marginTop: theme.spacing(2),
  },
}));

export default Drawer;
