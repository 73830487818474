/*
 * Created by Andre Richards on 3 March 2021.
 */

import React from 'react';
import PropTypes from 'prop-types';
import {
  TableColumnBuilder,
  TableSortDirection,
  VirtualTable
} from '@hti-ui/react-web-material';
import {IconButton} from "@material-ui/core";
import {DeleteOutlineRounded as DeleteIcon} from '@material-ui/icons';
import {deletePaymentGatewayConfig} from "../../actions/paymentGatewayConfigActions";
import useActions from "../../hooks/useActions";
import useActionConfirmation from "../../hooks/useActionConfirmation";
import useGlobalMessenger from "../../hooks/useGlobalMessenger";

/**
 * A table for listing PaymentGatewayConfigs.
 */
const Table = props => {

  const {
    disabled, searchTerm,
    paymentGatewayConfig, paymentGatewayConfigs, onPaymentGatewayConfigSelected,
  } = props;

  const [_deletePaymentGatewayConfig] = useActions([deletePaymentGatewayConfig]);
  const [confirmAction, confirmationDialog] = useActionConfirmation();
  const {dispatchSuccessMessage, handleError} = useGlobalMessenger();

  const [columns, setColumns] = React.useState([]);
  React.useEffect(() => {
    setColumns([

      new TableColumnBuilder()
      .id('uniqueKey').label('Unique Key')
      .sortable().sortDirection(TableSortDirection.ASC)
      .searchable()
      .build(),

      new TableColumnBuilder()
      .id('displayName').label('Display Name')
      .sortable()
      .searchable()
      .build(),

      new TableColumnBuilder()
      .id('customerName').label('Customer')
      .sortable()
      .searchable()
      .build(),

      new TableColumnBuilder()
      .id('paymentGatewayName').label('Payment Gateway')
      .sortable()
      .searchable()
      .build(),

      TableColumnBuilder.ActionColumn().build(),

    ]);
  }, []);

  /**
   * Checks if a paymentGatewayConfig in the table is the selected paymentGatewayConfig.
   */
  const isSelected = React.useCallback((other) => (
      paymentGatewayConfig?.id === other.id
  ), [paymentGatewayConfig]);

  /**
   * Deletes a PaymentGatewayConfig with user confirmation.
   */
  const onDeleteClicked = React.useCallback((paymentGatewayConfig) => {
    confirmAction(() => {

      _deletePaymentGatewayConfig({id: paymentGatewayConfig?.id})
      .then(() => {
        dispatchSuccessMessage('The Payment Gateway Config was deleted.');
      })
      .catch(e => {
        handleError(e, 'The Payment Gateway Config could not be deleted.');
      });

    }, {
      title: 'Delete Payment Gateway Config?',
      message: `Are you sure you want to delete '${paymentGatewayConfig.uniqueKey}'?`,
      confirmLabel: 'Delete',
    });
  }, [
     _deletePaymentGatewayConfig,
    confirmAction, dispatchSuccessMessage, handleError,
  ]);

  /**
   * Action definitions for each row in the table.
   */
  const actions = React.useMemo(() => ([
    {
      Component: IconButton,
      componentProps: {children: (<DeleteIcon/>)},
      onClick: onDeleteClicked,
      tooltip: 'Delete Payment Gateway Config'
    }
  ]), [onDeleteClicked]);

  return (<React.Fragment>

    <VirtualTable
        searchText={searchTerm}
        columns={columns}
        onColumnLabelClicked={setColumns}
        actions={actions}
        data={paymentGatewayConfigs}
        emptyListMessage={'No Payment Gateway Configs found...'}
        onRowSelected={onPaymentGatewayConfigSelected}
        isSelectedRow={isSelected}
        disabled={disabled}
    />

    {confirmationDialog}

  </React.Fragment>);

};

Table.propTypes = {
  searchTerm: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  paymentGatewayConfig: PropTypes.object,
  paymentGatewayConfigs: PropTypes.array.isRequired,
  onPaymentGatewayConfigSelected: PropTypes.func.isRequired,
};

export default Table;
