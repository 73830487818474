const configFields = {
  UNIQUE_KEY: 'Unique Key',
  CUSTOMER: 'Customer',
  CUSTOMER_ID: 'Customer ID',
  PAYMENT_GATEWAY : 'Payment Gateway',
  MERCHANT_ID : 'Merchant ID',
  SITE_ID : 'Site ID',
  SECRET_KEY : 'Secret Key',
  SECRET_TOKEN : 'Secret Token',
  USERNAME : 'Username',
  PASSWORD : 'Password',
  ACQUIRER : 'Acquirer',
  DISPLAY_NAME : 'Display Name',
  NOTE : 'Note',
  TEST_MODE : 'Test Mode',
  TOKENIZE: 'Tokenize',
  SKIP_SUCCESS_PAGE : 'Skip Success Page',
  SKIP_ERROR_PAGE : 'Skip Error Page'
};

export default configFields;