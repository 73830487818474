/*
 * Created by Andre Richards on 3 March 2021.
 */

import {
  LocalCacheRegistry,
  LocalCache,
  CacheLifetime
} from '@hti-ui/local-cache';
import HttpManager from "../util/httpManager";
import PaymentGatewayConfigActionTypes from "../constants/action-types/paymentGatewayConfigActionTypes";

const cache = new LocalCache();
LocalCacheRegistry.register(cache);

/**
 * The modelled entity on the server, for which all operations below are
 * performed.
 * @type {string}
 */
const entity = 'paymentGatewayConfig';

/**
 * Fetches a list of PaymentGatewayConfigs.
 *
 * @param {Object} args The function arguments, including request parameters.
 * @param {boolean} [args.force=false] Should local cache be invalidated?
 * @return {function(*,*): Promise}
 */
export const fetchPaymentGatewayConfigs = (args) => (dispatch) => {

  const {force = false} = args;
  const key = 'all-paymentGatewayConfigs';

  if (!force && cache.has(key)) {
    return cache.get(key);
  }

  dispatch({type: PaymentGatewayConfigActionTypes.REQUEST});
  const request = HttpManager.get({entity});

  cache.clear();
  cache.set(key, CacheLifetime.SHORT, request);

  return request
  .then(r => {
    dispatch({type: PaymentGatewayConfigActionTypes.SET, data: r?.data?.data ?? []});
    return r;
  })
  .catch(e => {
    cache.delete(key);
    dispatch({type: PaymentGatewayConfigActionTypes.FAIL_REQUEST});
    throw e;
  })

};

/**
 * Creates a new PaymentGatewayConfig.
 *
 * @param {Object} args The request parameters.
 * @param {string} args.uniqueKey The new PaymentGatewayConfig's uniqueKey.
 * @param {string} args.customerId The new PaymentGatewayConfig's customerId.
 * @param {string} args.paymentGatewayId The new PaymentGatewayConfig's paymentGatewayId.
 * @param {string} args.merchantId The new PaymentGatewayConfig's merchantId.
 * @param {string} args.siteId The new PaymentGatewayConfig's siteId.
 * @param {string} args.secretKey The new PaymentGatewayConfig's secretKey.
 * @param {string} args.secretToken The new PaymentGatewayConfig's secretToken.
 * @param {string} args.username The new PaymentGatewayConfig's username.
 * @param {string} args.password The new PaymentGatewayConfig's password.
 * @param {string} args.note The new PaymentGatewayConfig's note.
 * @return {function(*,*): Promise}
 */
export const createPaymentGatewayConfig = (args) => (dispatch) => {
  const {
    // uniqueKey,
    customerId,
    paymentGatewayId,
    merchantId,
    siteId,
    secretKey,
    secretToken,
    username,
    password,
    note,
      displayName,
      testMode,
      acquirer,
      tokenize
  } = args;
  dispatch({type: PaymentGatewayConfigActionTypes.REQUEST});
  return HttpManager.post({
    entity,
    payload: {
      // uniqueKey,
      customerId,
      paymentGatewayId,
      merchantId,
      siteId,
      secretKey,
      secretToken,
      username,
      password,
      note,
      displayName,
      testMode,
      acquirer,
        tokenize
    }
  })
  .then(r => {
    dispatch(
        {type: PaymentGatewayConfigActionTypes.CREATE, data: r.data?.data});
    return r;
  })
  .catch(e => {
    dispatch({type: PaymentGatewayConfigActionTypes.FAIL_REQUEST});
    throw e;
  });
};

/**
 * Updates an existing PaymentGatewayConfig.
 *
 * @param {Object} args The request parameters.
 * @param {string} args.id The PaymentGatewayConfig's ID.
 * @param {string} args.uniqueKey The PaymentGatewayConfig's new uniqueKey.
 * @param {string} args.customerId The PaymentGatewayConfig's new customerId.
 * @param {string} args.paymentGatewayId The PaymentGatewayConfig's new paymentGatewayId.
 * @param {string} args.merchantId The PaymentGatewayConfig's new merchantId.
 * @param {string} args.siteId The PaymentGatewayConfig's new siteId.
 * @param {string} args.secretKey The PaymentGatewayConfig's new secretKey.
 * @param {string} args.secretToken The PaymentGatewayConfig's new secretToken.
 * @param {string} args.username The PaymentGatewayConfig's new username.
 * @param {string} args.password The PaymentGatewayConfig's new password.
 * @param {string} args.note The PaymentGatewayConfig's new note.
 * @return {function(*,*): Promise}
 */
export const updatePaymentGatewayConfig = (args) => (dispatch) => {
  const {
    id,
    uniqueKey,
    customerId,
    paymentGatewayId,
    merchantId,
    siteId,
    secretKey,
    secretToken,
    username,
    password,
    note,
      displayName,
      testMode,
    acquirer,
      tokenize
  } = args;
  dispatch({type: PaymentGatewayConfigActionTypes.REQUEST});
  return HttpManager.put(
      {
        entity, payload: {
          id,
          uniqueKey,
          customerId,
          paymentGatewayId,
          merchantId,
          siteId,
          secretKey,
          secretToken,
          username,
          password,
          note,
          displayName,
          testMode,
          acquirer,
              tokenize
        }
      })
  .then(r => {
    dispatch(
        {type: PaymentGatewayConfigActionTypes.UPDATE, data: r.data?.data});
    return r;
  })
  .catch(e => {
    dispatch({type: PaymentGatewayConfigActionTypes.FAIL_REQUEST});
    throw e;
  });
};

/**
 * Deletes an existing PaymentGatewayConfig.
 *
 * @param {Object} args The request parameters.
 * @param {number} args.id The PaymentGatewayConfig's ID.
 * @return {function(*,*): Promise}
 */
export const deletePaymentGatewayConfig = (args) => (dispatch) => {
  const {id} = args;
  dispatch({type: PaymentGatewayConfigActionTypes.REQUEST});
  return HttpManager.delete({entity, payload: {id}})
  .then(r => {
    dispatch({type: PaymentGatewayConfigActionTypes.DELETE, data: {id}});
    return r;
  })
  .catch(e => {
    dispatch({type: PaymentGatewayConfigActionTypes.FAIL_REQUEST});
    throw e;
  });
};
