/*
 * Created by Andre Richards on 3 March 2021.
 */

import React from 'react';
import PropTypes from 'prop-types';
import {
  SideDrawer,
  SideDrawerContent,
  SideDrawerToolbar,
  TextField,
} from "@hti-ui/react-web-material";
import {Button, FormControlLabel, FormHelperText, Grid, Switch} from "@material-ui/core";
import useGlobalMessenger from "../../hooks/useGlobalMessenger";
import useActions from "../../hooks/useActions";
import {updatePaymentGateway} from "../../actions/paymentGatewayActions";
import paymentGatewayFields from "./Fields";
import gatewayFields from "../Util/GatewayFields"


/**
 * A drawer for creating and updating a PaymentGateway entity.
 */
const Drawer = props => {

  const {visible, disabled, dismiss, paymentGateway} = props;
  const isNewPaymentGateway = !paymentGateway;
  const paymentGatewayId = paymentGateway?.id;

  const {dispatchSuccessMessage, handleError} = useGlobalMessenger();
  const [
    // _createPaymentGateway,
    _updatePaymentGateway,
  ] = useActions([
    // createPaymentGateway,
    updatePaymentGateway,
  ]);

  const [name, setName] = React.useState('');
  const [redirectUrl, setRedirectUrl] = React.useState('');
  const [initiateUrl, setInitiateUrl] = React.useState('');
  const [apiUrl, setApiUrl] = React.useState('');
  const [queryUrl, setQueryUrl] = React.useState('');
  const [refundUrl, setRefundUrl] = React.useState('');
  const [displayName, setDisplayName] = React.useState('');
  const [isEcommerce, setIsEcommerce] = React.useState('');
  const [isDisabled, setIsDisabled] = React.useState('');

  React.useEffect(() => {
    setName(paymentGateway?.name ?? '');
    setRedirectUrl(paymentGateway?.redirectUrl ?? '');
    setInitiateUrl(paymentGateway?.initiateUrl ?? '');
    setApiUrl(paymentGateway?.apiUrl ?? '');
    setQueryUrl(paymentGateway?.queryUrl ?? '');
    setRefundUrl(paymentGateway?.refundUrl ?? '');
    setDisplayName(paymentGateway?.displayName ?? '');
    setIsEcommerce(paymentGateway?.isEcommerce ?? '');
    setIsDisabled(paymentGateway?.disabled ?? false);
  }, [visible, paymentGateway]);

  const canCommit = !!name.trim();

/*
  /!**
   * Creates a new PaymentGateway. If successful, the drawer is dismissed.
   * The user is notified of the success or failure of the request.
   *!/
  const onCreateClicked = React.useCallback(() => {

    _createPaymentGateway({name, redirectUrlrectUrl, apiUrl, queryUrl, displayName})
    .then(() => {
      dispatchSuccessMessage('The Payment Gateway has been created.');
      dismiss();
    })
    .catch(e => void handleError(e, 'The Payment Gateway could not be created.'));

  }, [
    name, redirectUrl, apiUrl, queryUrl,displayName,
    _createPaymentGateway,
    dispatchSuccessMessage, handleError,
    dismiss,
  ]);
*/

  /**
   * Updates an existing PaymentGateway. If successful, the drawer is dismissed.
   * The user is notified of the success or failure of the request.
   */
  const onUpdateClicked = React.useCallback(() => {

    _updatePaymentGateway({id: paymentGatewayId,
      name,
      redirectUrl,
      apiUrl,
      queryUrl,
      displayName,
      refundUrl,
      initiateUrl,
    isDisabled})
    .then(() => {
      dispatchSuccessMessage('The Payment Gateway has been updated.');
      dismiss();
    })
    .catch(e => void handleError(e, 'The Payment Gateway could not be updated.'));

  }, [
    paymentGatewayId, name, redirectUrl, apiUrl, queryUrl, displayName,
    _updatePaymentGateway, dispatchSuccessMessage, handleError,
    dismiss, refundUrl,initiateUrl, isDisabled
  ]);


  const getFieldDetails = React.useCallback((gatewayName, configField) => {
    const  fields = gatewayFields;
    const gatewayFieldsMap = fields[gatewayName];
    const configFields = gatewayFieldsMap['payment_gateway_fields'];
    return configFields.get(configField);
  }, [])

  const isRequired = React.useCallback((configField) => {
    if (paymentGateway != null) {
      const gatewayName = paymentGateway?.name;
      const fieldDetails = getFieldDetails(gatewayName, configField);
      if (fieldDetails != null) {
        if (fieldDetails['required']) {
          return true;
        } else {
          return false;
        }
        return true;
      } else {
        return false;
      }
    } else {
      // no gateway found
      return true;
    }

  }, [paymentGateway]);

  const addGatewayFieldNameToLabel = React.useCallback((configField) => {
    if (paymentGateway != null) {
      const gatewayName = paymentGateway?.name;
      const fieldDetails = getFieldDetails(gatewayName, configField);
      if (fieldDetails != null) {
        if (fieldDetails['gatewayName']) {
          return configField + " / " + fieldDetails['gatewayName'];
        } else {
          return configField;
        }
        return configField;
      } else {
        return configField;
      }
    } else {
      // no gateway found
      return configField;
    }

  }, [paymentGateway]);

  const showField = React.useCallback((configField) => {
    if (paymentGateway != null) {
      const gatewayName = paymentGateway?.name;
      const fieldDetails = getFieldDetails(gatewayName, configField);
      if (fieldDetails != null) {
        return true;
      } else {
        return false;
      }
    } else {
      // no gateway found
      return true;
    }
  }, [paymentGateway]);


  return (<SideDrawer visible={visible} sm={6} xs={12}>

    <SideDrawerToolbar
        title={isNewPaymentGateway
            ? 'New Payment Gateway'
            : 'Updating Payment Gateway'}
    >

      <Button
          variant={'outlined'}
          onClick={dismiss}
          disabled={disabled}
      >Cancel</Button>

      <Button
          variant={'contained'}
          color={'secondary'}
          onClick={/*isNewPaymentGateway ? onCreateClicked :*/ onUpdateClicked}
          disabled={disabled || !canCommit}
      >{isNewPaymentGateway ? 'Create' : 'Update'}</Button>

    </SideDrawerToolbar>

    <SideDrawerContent>

      <Grid container spacing={1}>

        <Grid item xs={12}>
          <TextField
              label={paymentGatewayFields.NAME}
              value={name}
              onChange={setName}
              fullWidth
              disabled
          />
        </Grid>

        <Grid item xs={12} hidden={!showField(paymentGatewayFields.REDIRECT_URL)}>
          <TextField
              label={addGatewayFieldNameToLabel(paymentGatewayFields.REDIRECT_URL)}
              value={redirectUrl}
              onChange={setRedirectUrl}
              fullWidth
          />
        </Grid>

        <Grid item xs={12} hidden={!showField(paymentGatewayFields.INITIATE_URL)}>
          <TextField
              label={addGatewayFieldNameToLabel(paymentGatewayFields.INITIATE_URL)}
              value={initiateUrl}
              onChange={setInitiateUrl}
              fullWidth
          />
        </Grid>

        <Grid item xs={12} hidden={!showField(paymentGatewayFields.API_URL)}>
          <TextField
              label={addGatewayFieldNameToLabel(paymentGatewayFields.API_URL)}
              value={apiUrl}
              onChange={setApiUrl}
              fullWidth
          />
        </Grid>

        <Grid item xs={12} hidden={!showField(paymentGatewayFields.QUERY_URL)}>
          <TextField
              label={addGatewayFieldNameToLabel(paymentGatewayFields.QUERY_URL)}
              value={queryUrl}
              onChange={setQueryUrl}
              fullWidth
          />
        </Grid>

        <Grid item xs={12} hidden={!showField(paymentGatewayFields.REFUND_URL)}>
          <TextField
              label={addGatewayFieldNameToLabel(paymentGatewayFields.REFUND_URL)}
              value={refundUrl}
              onChange={setRefundUrl}
              fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
              label={paymentGatewayFields.IS_ECOMMERCE}
              value={isEcommerce}
              disabled
              // onChange={setRefundUrl}
              fullWidth
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
              label={paymentGatewayFields.DISPLAY_NAME}
              value={displayName}
              onChange={setDisplayName}
              fullWidth
              disabled
              helperText={"This is a default that will be displayed when the user needs to select"
              + "which payment gateway they would like to use. This can be overridden on each of the "
              + "Payment Gateway Configurations"}
          />
        </Grid>

        <Grid item xs={12}>
          <FormControlLabel
              label={"Disabled"}
              control={<Switch
                  checked={isDisabled}
                  onChange={({target: {checked}}) => setIsDisabled(checked)}
              />}
          />
          <FormHelperText>This only stops the payment gateway to not show on the select item
            on Payment Gateway Config screen. It does not affect existing implementations.</FormHelperText>
        </Grid>

      </Grid>

    </SideDrawerContent>

  </SideDrawer>);

};

Drawer.propTypes = {
  visible: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  dismiss: PropTypes.func.isRequired,
  paymentGateway: PropTypes.object,
};

export default Drawer;
